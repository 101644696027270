import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Visites virtuelles 3D Matterport"
      />
<div className="mx-auto max-w-screen-xl">
  <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
    <h1>Nos réalisations avec 3D Matterport - Immobilier</h1>
    <div className="text-center max-w-xl mx-auto">
    </div>
  </div>
  
  <div className="text-center mt-10">
    <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">Appartements aux Carmes à Toulouse</h2>      
    <div className="iframe-container">
      <iframe src='https://my.matterport.com/show/?m=zv7PvsxDc7X'></iframe>
    </div>
  </div>
</div>
    </Layout>
  );
}

export default VisitePage;
